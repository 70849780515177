<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="时间" show-overflow-tooltip width="135">
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.createTime
                                ? scope.row.createTime.slice(0, 16)
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="所属部门" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-tooltip placement="top"
                        >·
                        <div slot="content">
                            <p
                                style="line-height: 2"
                                v-for="item in scope.row.departmentAdminDOList"
                                :key="item.id"
                            >
                                {{ item.departmentName }}
                            </p>
                        </div>
                        <em style="font-style: normal">
                            {{ returnName(scope.row) }}
                        </em>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="成员"
                prop="companyDomain"
                width="55"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.adminName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="结算周期" show-overflow-tooltip width="190">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.billStartTime
                            ? scope.row.billStartTime.slice(0, 10)
                            : '- -'
                    }}</span>
                    ~
                    <span>
                        {{
                            scope.row.billEndTime
                                ? scope.row.billEndTime.slice(0, 10)
                                : '- -'
                        }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="账单编号" width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span> {{ scope.row.billNo || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="账单类型"
                width="80"
                prop="name"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.type == 1 ? '全款订单' : '全部订单' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="合同金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span> {{ scope.row.contractTotalAmount || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="回款金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.actualTotalAmount || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="业绩"
                width="80"
                prop="name"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.achievementTotalAmount || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="状态"
                width="60"
                prop="name"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{ $tableDataHandle.billStatus(scope.row.status) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button
                        style="color: #2370eb"
                        v-if="btnP.detail"
                        type="text"
                        @click="onDetails(scope.row)"
                        >明细</el-button
                    >
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        v-if="btnP.send && scope.row.status == 1"
                        @click="onSend(scope.row)"
                        >发送</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>

<script>
import { billList, billListStatistics } from '@/api/count/business.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            tableRow: {},
            dialog: false,
            btnP: {},
            pageData: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(active, searchVal, pageNum, pageSize, btnP) {
            this.btnP = btnP;
            let data = {
                param: {},
                pageSize: pageSize,
                pageNum: pageNum,
            };
            if (btnP.userSearch && !btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');

                if (searchVal.adminId) {
                    data.param.adminId = searchVal.adminId;
                }
            }

            if (btnP.depSearch) {
                if (searchVal.adminId) {
                    data.param.adminId = searchVal.adminId;
                }
                if (searchVal.departmentId) {
                    data.param.departmentId = searchVal.departmentId;
                }
            }
            if (!btnP.userSearch && !btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (active == 0) {
                data.param.statusList = [1, 2, 3, 4, 5];
            } else {
                data.param.statusList = [active];
            }
            this.pageData = {
                active: active,
                searchVal: searchVal,
                pageNum: pageNum,
                pageSize: pageSize,
            };
            billList(data).then((res) => {
                if (res.code == 200) {
                    this.$emit('totalNum', res.data.total);
                    this.tableData = res.data.list;
                }
            });
            billListStatistics({
                param: {
                    ...data.param,
                    statusList: [4],
                },
            }).then((res) => {
                if (res.code == 200) {
                    this.$emit('billTotal', res.data);
                }
            });
        },
        onDetails(row) {
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
            this.$router.push({
                path: '/businessCountdetails',
                query: {
                    id: row.id,
                    status: row.status,
                },
            });
        },

        onSend(row) {
            this.$emit('onSend', row);
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    .icon-ziyuanxhdpi {
        color: #2370eb;
        cursor: pointer;
        vertical-align: -1px;
    }
}

/deep/ .el-table .cell {
    text-overflow: initial;
    padding-right: 0;
}
.dl_con {
    width: 80%;
    margin: 35px auto 100px;
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 14px;
    i {
        font-weight: 400;
        font-size: 60px;
        color: #f5a623;
        display: block;
        margin-bottom: 20px;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
    .el-button--primary {
        background-color: #2370eb;
    }
}
</style>
