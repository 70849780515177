<template>
    <div class="deal_with_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span class="btn1 active" @click="allPayOrder(1)">
                        <img src="@/img/ERP_huokuanzongjine.svg" alt="" />
                        全款订单</span
                    >
                    <span class="btn2 active" @click="allPayOrder(2)">
                        <img src="@/img/dingdan1.svg" alt="" />
                        全部订单</span
                    >
                    <Department
                        ref="department"
                        :departmentWidth="180"
                        :adminWidth="180"
                        :depSearch="btnP.depSearch"
                        :searchUser="btnP.userSearch"
                        @searchData="searchData"
                    ></Department>
                    <!-- <span v-if="btnP.depSearch">部门</span>
                    <el-select
                        v-if="btnP.depSearch"
                        v-model="searchVal.departmentId"
                        size="small"
                        filterable
                        style="width: 180px"
                        clearable
                        placeholder="请选择"
                        @change="changeDepartmentId"
                    >
                        <el-option
                            v-for="item in departmentList"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <span v-if="btnP.userSearch">成员</span>
                    <el-select
                        v-if="btnP.userSearch"
                        style="width: 180px"
                        v-model="searchVal.adminId"
                        size="small"
                        filterable
                        clearable
                        placeholder="请选择"
                        @change="getTableData"
                    >
                        <el-option
                            v-for="item in userName"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select> -->
                </p>
                <p>
                    <el-button
                        type="primary"
                        style="
                            margin-right: 16px;
                            border-color: #2370eb;
                            background: #2370eb;
                        "
                        size="small"
                        @click="getTableData2()"
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                            margin-right: 16px;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>
        </div>
        <div class="table-title">
            <div>
                <div class="title_left_btn">
                    <span
                        @click="getTableData2(0)"
                        :class="active == 0 ? 'active' : ''"
                        >全部</span
                    >
                    <span
                        @click="getTableData2(1)"
                        :class="active == 1 ? 'active' : ''"
                        >待发送</span
                    >
                    <span
                        @click="getTableData2(2)"
                        :class="active == 2 ? 'active' : ''"
                        >待确认</span
                    >
                    <span
                        @click="getTableData2(3)"
                        :class="active == 3 ? 'active' : ''"
                        >已确认</span
                    >
                    <el-badge :value="rejectNum" class="item">
                        <span
                            @click="getTableData2(4)"
                            :class="active == 4 ? 'active' : ''"
                            >已驳回</span
                        >
                    </el-badge>
                    <span
                        @click="getTableData2(5)"
                        :class="active == 5 ? 'active' : ''"
                        >已过期</span
                    >
                </div>
            </div>
        </div>
        <Table
            ref="table"
            @totalNum="totalNum"
            @onSend="onSend"
            @billTotal="billTotal"
        ></Table>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 生成账单 -->
        <el-dialog
            :visible.sync="dialogGenerate"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    生成账单
                </div>
            </template>
            <div class="dialog_info">
                <el-form
                    label-position="left"
                    style="margin: 0 auto"
                    size="small"
                    label-width="90px"
                >
                    <el-form-item label="账单范围" required>
                        <el-radio-group v-model="form.radio">
                            <el-radio :label="1">全员</el-radio>
                            <el-radio :label="2">个人</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item
                        label="员工"
                        required
                        v-show="form.radio == 2"
                    >
                        <el-select
                            style="width: 270px"
                            v-model="form.adminId"
                            size="small"
                            filterable
                            clearable
                            placeholder="输入员工名称搜索选择"
                        >
                            <el-option
                                v-for="item in allUserList"
                                :key="item.id"
                                :label="item.nickName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    style="margin-right: 16px; background-color: #2370eb"
                    round
                    @click="billCreate"
                    size="mini"
                    >保 存</el-button
                >
            </div>
        </el-dialog>
        <!-- 发送 -->
        <el-dialog
            :visible.sync="dialogSend"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    发送账单
                </div>
            </template>
            <div class="dialog_info">
                <p>
                    <i
                        class="el-icon-warning-outline"
                        style="color: #ff8a00"
                    ></i>
                    即将发送当前账单，是否确认？
                </p>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    style="margin-right: 16px; background-color: #2370eb"
                    round
                    @click="onSubmitSend"
                    size="mini"
                    >保 存</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { roleList } from '@/api/customer/customer';
import { departmentList } from '@/api/admin/framework/framework.js';
import { billCreate, billUpdate } from '@/api/count/business.js';
import Table from '../table/table.vue';
import Department from '../../../../components/Department';
export default {
    data() {
        return {
            active: 0,
            currentPage: 1,
            pagesize: 20,
            total: 0,
            searchVal: {
                adminId: '',
            },
            direction: 'rtl',
            dialogGenerate: false,
            dialogSend: false,
            tableRow: {},
            departmentList: [],
            userName: [],
            allUserList: [],
            btnP: {},
            form: {
                radio: 1,
            },
            rejectNum: 0,
            isPage: false,
            tag1: false,
            tag2: false,
            nowDateDay: new Date().getDate(),
        };
    },
    components: {
        Table,
        Department,
    },
    created() {},
    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            this.getPageData();
            if (btn.depSearch) {
                this.getDepartmentList();
            }
            if (btn.userSearch && !btn.depSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            if (btn.fullpay || btn.allorder) {
                this.getAllUser();
            }
        },
        getPageData() {
            if (
                sessionStorage.getItem('pageData') &&
                JSON.parse(sessionStorage.getItem('pageData'))
            ) {
                this.currentPage = JSON.parse(
                    sessionStorage.getItem('pageData')
                ).pageNum;
                this.pagesize = JSON.parse(
                    sessionStorage.getItem('pageData')
                ).pageSize;
                this.searchVal =
                    JSON.parse(sessionStorage.getItem('pageData')).searchVal ||
                    {};
                this.$refs.department.$data.departmentId =
                    JSON.parse(sessionStorage.getItem('pageData')).param
                        .departmentId || undefined;
                this.$refs.department.$data.adminId =
                    JSON.parse(sessionStorage.getItem('pageData')).param
                        .adminId || '';
                if (this.searchVal.departmentId) {
                    if (this.btnP.userSearch) {
                        this.$refs.department.getUserList(
                            JSON.parse(sessionStorage.getItem('pageData')).param
                                .departmentId
                        );
                    }
                }
                this.active = JSON.parse(
                    sessionStorage.getItem('pageData')
                ).active;
                sessionStorage.removeItem('pageData');
                this.isPage = true;
            } else {
                this.isPage = true;
            }
            this.getData();
        },
        getAllUser() {
            let data = {
                param: {
                    roleTypeList: [3, 4, 8, 9, 10],
                },
            };
            roleList(data).then((res) => {
                this.allUserList = res.data;
            });
        },
        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.departmentList = res.data;
                    this.$refs.department.getData(this.btnP, res.data);
                }
            });
        },
        getTableData2(active) {
            this.active = active;
            this.pagesize = 20;
            this.currentPage = 1;
            this.getData();
        },
        getTableData() {
            this.currentPage = 1;
            this.getData();
        },
        searchData(data) {
            this.pagesize = 20;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            this.getData();
        },
        getData() {
            this.$refs.table.getData(
                this.active,
                this.searchVal,
                this.currentPage,
                this.pagesize,
                this.btnP
            );
        },
        totalNum(row) {
            this.total = row;
        },
        billTotal(data) {
            this.rejectNum = data.totalNumber || 0;
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },

        allPayOrder(type) {
            this.dialogGenerate = true;
            this.form.type = type;
        },
        billCreate() {
            let data = {
                param: {
                    type: this.form.type,
                },
            };
            if (this.form.radio == 2) {
                if (!this.form.adminId) {
                    return this.$message.error('请选择员工');
                }
                data.param.adminId = this.form.adminId;
            }
            billCreate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.form = {
                        radio: 1,
                    };
                    this.handleClose();
                }
            });
        },
        onSend(row) {
            this.tableRow = row;
            this.dialogSend = true;
        },
        onSubmitSend() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    status: 2,
                },
            };
            billUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        handleClose() {
            this.dialogGenerate = false;
            this.dialogSend = false;
            this.form = {
                radio: 1,
            };
            this.getData();
        },
        searchEmpty() {
            this.searchVal = {
                field: 1,
                followTime: '',
                followDate: [],
            };
            this.name = '';
            this.active = '全部';
            this.currentPage = 1;
            this.getData();
        },
        handleDrawer() {
            this.drawerAli = false;
            this.drawerHistory = false;
            this.drawerDetails = false;
            this.getData();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.deal_with_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                .btn1,
                .btn2 {
                    display: inline-block;
                    width: 96px;
                    height: 32px;
                    background: #f0f0f0;
                    box-shadow: 0px 2px 4px 0px rgba(252, 239, 198, 0.6);
                    border-radius: 2px;
                    text-align: center;
                    line-height: 32px;
                    font-weight: normal;
                    cursor: pointer;
                    img {
                        width: 16px;
                        height: 16px;
                        vertical-align: -3px;
                        margin-right: 3px;
                    }
                }
                .btn2.active {
                    background: #f7ece5;
                    margin: 0 8px 0 0;
                }
                .btn1.active {
                    background: #fcefc6;
                }
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
        .search-two {
            padding-bottom: 10px;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .title_left_btn {
            span {
                display: inline-block;
                padding: 4px 13px;
                border-radius: 20px;
                font-size: 13px;
                color: #666666;
                border: 1px solid #f0f0f0;
                cursor: pointer;
            }
            span + span {
                margin-left: 12px;
            }
            .active {
                background: #e9f2ff;
                color: #2370eb;
            }
        }
        .add {
            border-color: #d9d9d9;
            color: #fff;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 20px 0 12px;
            height: 26px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            background: #2370eb;
            i {
                margin-right: 6px;
                font-size: 12px;
            }
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Times {
        display: inline-block;
        width: 20px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        background: #ff760f;
        border-radius: 63px 63px 63px 1px;
        text-align: center;
    }
    .Timess {
        background: #ff9385;
        margin-left: 3px;
    }
}
.dialog_info {
    width: 85%;
    margin: 25px auto 0;
    padding-bottom: 110px;
    font-size: 14px;
    color: #333333;
    p {
        margin-bottom: 16px;
        font-weight: 600;
    }
    span {
        display: inline-block;
        width: 215px;
        height: 48px;
        background: #f9fafc;
        border-radius: 8px;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        i {
            color: #2370eb;
        }
    }
    .icon-zhuyi {
        display: block;
        margin: 0 auto 45px;
        font-size: 40px;
        color: #feb516;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
.item {
    margin-left: 12px;
    margin-top: -2px;
    margin-right: 12px;
}
/deep/ .el-badge__content.is-fixed {
    top: 5px;
    right: 12px;
}
</style>
