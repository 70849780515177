import http from '@/utils/http.js'

// 生成账单
export function billCreate(data) {
     return http({
          url: "/jasoboss/bill/create",
          method: "post",
          data
     })
}
// 列表
export function billList(data) {
     return http({
          url: "/jasoboss/bill/list",
          method: "post",
          data
     })
}
// 结算单列表
export function billSettleList(data) {
     return http({
          url: "/jasoboss/bill/settle/list",
          method: "post",
          data
     })
}
// 修改
export function billUpdate(data) {
     return http({
          url: "/jasoboss/bill/update",
          method: "post",
          data
     })
}
// 结算单修改
export function billSettleUpdate(data) {
     return http({
          url: "/jasoboss/bill/settle/update",
          method: "post",
          data
     })
}
// 更新结算单里面的订单
export function billSettleInfoUpdate(data) {
     return http({
          url: "/jasoboss/bill/settle/info/update",
          method: "post",
          data
     })
}
// 列表 单个
export function billListGet(data) {
     return http({
          url: "/jasoboss/bill/get",
          method: "post",
          data
     })
}
// 账单列表 表头驳回统计
export function billListStatistics(data) {
     return http({
          url: "/jasoboss/bill/list/statistics",
          method: "post",
          data
     })
}